import { ThemeOverrides, breakpoints, theme } from "@shapeable/theme";
import { WARM_GREY } from "@shapeable/ui";
import { css } from "styled-components";

export const YELLOW = '#FFCA04';
export const YELLOW_DARK = '#FCB10F';
export const YELLOW_ORANGE = '#F79616';

export const themeOverrides: ThemeOverrides = {
  light: {
    color: {

      darkBackground: '#202124',
      lightBackground: '#F8F8F8',
  
      midBrand: YELLOW_DARK,
      primary: YELLOW_DARK,
  
  
      linkHover: YELLOW_DARK,
      link: YELLOW_ORANGE,
      // linkInvertedHover: YELLOW,
      // linkActive: YELLOW,
  
      // menuItemLinkHover: YELLOW_DARK, 
      // menuItemLinkActive: YELLOW_DARK, 
      // invertedMenuItemLinkHover: YELLOW_DARK, 
  
    },
  },
};


/* 
--------------------

  Layout Styles
  
  NB: This is the appropriate place to override styling for this specific site, based on BEM-style class name targets 
  
--------------------
*/

export const LayoutStyles = breakpoints({
  base: css`

    .page-home .shp--page-layout-standard__banner-titles div div {
      flex-direction: column;
      gap: 0.25em;

      h1 {
        align-self: flex-start;
      }
    }

    .shp--slice-intro {
      font-size: 1.75em;
      text-align: left;
      line-height: 1.25em;
    }

    .shp--site-menu-bar__title {
      color: ${theme.COLOR('light')}
    }

    .shp--slice-key-message {
      text-align: left;

      .shp--content {
        font-family: ${theme.FONT('sans')};
        font-size: 2em;
        font-style: normal;
        font-weight: 300;
        line-height: 1.30em;
      }

      .shp--button {
        &:hover {
          color: ${theme.COLOR('strong')};
          border-color: ${theme.COLOR('strong')};
          ${theme.FILL('strong')};
        }
      }
      
      .shp--slice-key-message__links {
        align-items: flex-start;
      }
    }

    .shp--slice-layout-content-with-image {
      .shp--content-node {
        color: ${theme.COLOR('inherit')};
      }
      li::before {
        color: ${theme.COLOR('inherit')};
      }
    }

    #customers .shp--button {
      color: ${theme.COLOR('light')};
      ${theme.FILL('light')};
      border: 2px dotted ${theme.COLOR('light')};
      &:hover {
        color: ${theme.COLOR('#FCB10F')};
        ${theme.FILL('#FCB10F')};
        border: 2px dotted ${theme.COLOR('#FCB10F')};
      }
    }

    .shp--page-knowledge-hub-layout {
      .shp--tabs {
        font-size: 1.50em
      }
    }

  `,
  tablet: css`
    .shp--slice-intro {
      font-size: 2em;
      text-align: left;
      line-height: 1.25em;
    }

  `,
  desktop: css`
    .shp--slice-section-color-block {
      min-height: 600px;
    }

    .page-home .shp--page-layout-standard__banner-titles div div {
      gap: 1em;
    }

    
    
  `,
});