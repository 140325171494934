import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { useEntity } from '@shapeable/ui';
import { PageLayoutStandard } from '@shapeable/web';
import { BannerTitles } from '../entities/banner-titles';
import { classNames } from '@shapeable/utils';
const cls = classNames('page-layout-platform');

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const LayoutStyles = breakpoints({
  base: css`
    /* override title styles via BEM here to affect spacing */ 
    .shp--banner-titles {
      color: ${theme.COLOR('light')};
      strong, span {
        color: ${theme.COLOR('light')};
      }
    }

  `,
  desktop: css`

  `
});

// -------- Components -------->

const My = {
  Layout: styled(PageLayoutStandard)`${LayoutStyles}`,
};

export const PageLayoutPlatform: PageLayoutComponent = 
(props) => {
  const { className, slices, children } = props;
  const entity = useEntity(props.entity);

  return (
    <My.Layout 
      entity={entity}
      slices={slices}
      bannerTitles={<BannerTitles entity={entity} />}
      headerProps={{ 
        showOverlayGradient: true, 
        variant: 'overlay', 
        backgroundColor: '#000000',
        fadeColor: '#0000000', 
       }}
      className={className}
    />
  )
};