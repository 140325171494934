import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, ContentEntity, ContentNode, Entity, HasChildren, Page, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { ContentEntityRichSubtitle, ContentEntityRichTitle, useEntity } from '@shapeable/ui';
import { classNames } from '@shapeable/utils';
const cls = classNames('banner-titles');

// -------- Types -------->

export type BannerTitlesProps = Classable & HasChildren & {
  entity?: Page;
}

export const BannerTitlesDefaultProps: BannerTitlesProps = {
};

// -------- Child Component Props -------->

type ContainerProps = {

}

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: ${theme.UNIT(8)};
    `,
  tablet: css`
  `,
  desktop: css`
  `,
  
});

const MainTitleStyles = breakpoints({
  base: css`
    font-size: 1.2em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 500;
    `,
    tablet: css`
      font-size: 2.5em;
    `,
    desktop: css`
      font-size: 3.5em;
  `,
});

const SubTitleStyles = breakpoints({
  base: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 300;
    line-height: 1em;
    font-size: 1.2em;
    `,
  tablet: css`
    font-size: 2.5em;
  `,
  desktop: css`
    font-size: 3.5em;
  `,
});

const SplitTitleStyles = breakpoints({
  base: css`
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 12px;
    margin-bottom: ${theme.UNIT(10)};
    letter-spacing: -2px;
  `,
  desktop: css`
    margin-bottom: ${theme.UNIT(20)};
  
  `,
});

const SubtitlesStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('dark')};
    letter-spacing: -1px;
    line-height: 1em;
    font-size: 0.8em;
  `,
  tablet: css`
    font-size: 1em;
    display: flex;
  `,
  desktop: css`
    font-size: 1.5em;
  
  `,
});



// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    SplitTitles: styled.div`${SplitTitleStyles}`,
    MainTitle: styled(ContentEntityRichTitle)`${MainTitleStyles}`,
    SubTitle: styled(ContentEntityRichTitle)`${SubTitleStyles}`,

    Subtitles: styled(ContentEntityRichSubtitle)`${SubtitlesStyles}`,
};

export const BannerTitles: Shapeable.FC<BannerTitlesProps> = (props) => {
  const { className } = props;
  const entity = useEntity(props.entity);

  const { titleRich, subtitleRich } = entity;

  const lines = titleRich.text.split('\n');
  const mainTitleText = lines[0];
  const subTitlesText = lines.slice(1).join('\n');

  const mainTitle: ContentEntity = {
    titleRich: {
      text: mainTitleText
    }
  };

  const subTitles: ContentEntity = {
    titleRich: {
      text: subTitlesText
    }
  };

  return (
    <My.Container className={cls.name(className)}>
      <My.SplitTitles>
        <My.MainTitle entity={mainTitle}/>
        <My.SubTitle entity={subTitles}/>
      </My.SplitTitles>
      <My.Subtitles entity={entity}/>
    </My.Container>
  )
};

BannerTitles.cls = cls;
BannerTitles.defaultProps = BannerTitlesDefaultProps;